import axios from "axios";
import configData from "../config/config";
import userFilters from "./Constant";

// Method use to make api request to aws
const getUsers = async function (pageSize, pageToken = "", searchString = "", filterOption = "", queryParams = {}) {
  let url = `${configData.API_URL}/users?pageSize=${pageSize}`;
  let val = "";
  if (searchString && pageToken) {
    val = `${searchString}`;
    url = `${url}&searchString=${encodeURIComponent(val)}&lastEvaluatedUsername=${pageToken}`;
  } else if (pageToken) {
    url = `${url}&lastEvaluatedUsername=${pageToken}`;
  } else if (searchString && !filterOption?.length) {
    val = `${searchString}`;
    url = `${url}&searchString=${encodeURIComponent(val)}&filterOption=${userFilters.toString()}`;
  } else if (searchString && filterOption?.length) {
    val = `${searchString}`;
    url = `${url}&searchString=${encodeURIComponent(val)}&filterOption=${filterOption}`;
  }
  const res = await axios({
    method: "GET",
    url,
    params: queryParams,
  });
  return res;
};

// function use to make api call for create user data
const createUserservice = async function (data) {
  /* eslint-disable no-param-reassign */
  data.email = data.email.toLowerCase();
  const response = await axios({
    method: "POST",
    url: `${configData.API_URL}/users`,
    data: JSON.stringify(data),
  });
  return response;
};

// function use to make api call to get user data by Email Id
const getById = async function (email) {
  const response = await axios({
    method: "GET",
    url: `${configData.API_URL}/users/${encodeURIComponent(email)}`,
  });
  return response;
};

// function use to make api call for update user data
const updateUserservice = async function (data) {
  const response = await axios({
    method: "PUT",
    url: `${configData.API_URL}/users`,
    data: JSON.stringify(data),
  });
  return response;
};

// function use to make api call to change user status (enable/disable)
const statusUserservice = async function (email, status) {
  const data = { email, userStatus: status };
  const response = await axios({
    method: "PUT",
    url: `${configData.API_URL}/users/status`,
    data: JSON.stringify(data),
  });
  return response;
};

const UserService = {
  getUsers,
  createUserservice,
  getById,
  updateUserservice,
  statusUserservice,
};

export default UserService;
