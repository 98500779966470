// NotFound Component
import React from "react";
import { useHistory } from "react-router-dom";
import WhelenRImg from "../../assets/img/logo.svg";
import LoginFooter from "./LoginFooter";

const NotFound = () => {
  const history = useHistory();

  const handleBack = () => {
    history.push("/users");
  };

  return (
    <>
      <div className="not-found-page">
        <div className="not-found-wrapper fluid-wrapper ">
          <img alt="Whelen" src={WhelenRImg} className="logo mb-4 pb-2" />
          <h1 className="not-found-title">404</h1>
          <h4 className="not-found-message">The page you requested could not be found.</h4>
          <button type="button" className="back-button btn btn-primary" onClick={handleBack}>
            <i className="fa fa-arrow-left left-arrow-icon" />
            BACK TO HOME
          </button>
        </div>
        <div className="not-found-footer">
          <LoginFooter />
        </div>
      </div>
    </>
  );
};

export default NotFound;
