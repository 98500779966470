// configList Components
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { renderToString } from "react-dom/server";
import Select from "react-select";

// Ui Components
import { jsPDF as JsPDF } from "jspdf";
import PageTitle from "../../Common/PageTitle";
// Services
import configService from "../../../services/ConfigService";
import commonUtiles from "../../../services/CommonUtiles";
import authService from "../../../services/AuthService";
import BrandIcon from "../../../assets/img/whelen-logo.png";
import Pdf from "./Pdf";

const ConfigurationList = () => {
  const [configData, setConfigData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showReset, setShowReset] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [pageSize, setPageSize] = useState(commonUtiles.getPageSizes[1]);
  const [isPrevBtnActive, setIsPrevBtnActive] = useState("disabled");
  const [isNextBtnActive, setIsNextBtnActive] = useState("");
  const [prePageTokens, setPrePageTokens] = useState([]);
  const [nextPageToken, setNextPageToken] = useState("");
  const sortInside = {
    column: "configName",
    direction: "desc",
  };
  const [sort, setSort] = useState(sortInside);
  const [configList, setConfigList] = useState([]);

  // Function use for sorting table data in ascending and descending order.
  const onSort = (column, data, listing) => {
    const direction = !listing ? (sort.direction === "asc" ? "desc" : "asc") : sort.direction;
    const allEqual = data.every((item) => data[0][column] === item[column]);
    let sortedData;
    if (allEqual) {
      sortedData = data.reverse();
    } else {
      sortedData = data.sort((a, b) => commonUtiles.compareValues(a, b, column, direction));
      if (direction === "desc") {
        sortedData.reverse();
      }
    }
    setConfigData(sortedData);
    setConfigList(sortedData);
    setSort({
      column,
      direction,
    });
  };

  // Method use to get configData' data
  const getConfigurations = (pageToken, filterVal, pageVal) => {
    setLoading(true);
    configService
      .getConfigurations(pageVal.value, pageToken, filterVal)
      .then((response) => {
        setLoading(false);
        onSort(sort.column, response.data.configData, true);
        if (response.data.lastEvaluatedKey && response.data.configData.length) {
          setNextPageToken(response.data.lastEvaluatedKey.id);
          setIsNextBtnActive("");
        } else {
          setIsNextBtnActive("disabled");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // Function uses for clear filter data
  const onLoad = () => {
    setPrePageTokens([]);
    setIsPrevBtnActive("disabled");
    setNextPageToken("");
    setSearchVal("");
    setShowReset(false);
    getConfigurations("", "", pageSize);
  };

  const getStdTypes = () => {
    configService
      .getSystemTypes()
      .then((response) => {
        const stdList = [];
        const footerList = [];
        response.data.forEach((element) => {
          const obj = {};
          obj.value = element.id;
          obj.label = element.name;
          stdList.push(obj);
          const footerObj = {};
          footerObj.value = element.id;
          footerObj.label = element.footerText;
          footerList.push(footerObj);
        });
        localStorage.setItem("standardList", JSON.stringify(stdList));
        localStorage.setItem("footerList", JSON.stringify(footerList));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Function is used to get config data on page load.
  useEffect(async () => {
    const stdList = await configService.getStandardListFromLocalStorage();
    if (!stdList) {
      await getStdTypes();
    }
    onLoad();
  }, []);

  // Function uses to filter configData' data
  const handleSearch = () => {
    if (searchVal) {
      setNextPageToken("");
      setIsPrevBtnActive("disabled");
      setPrePageTokens([]);
      getConfigurations("", searchVal, pageSize);
      setShowReset(true);
    }
  };
  // Enter key handler
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  // Function uses for clear filter data
  const onResetHandler = (e) => {
    e.preventDefault();
    onLoad();
  };

  // function invoke on Next button click
  const btnNextClick = (e) => {
    e.preventDefault();
    prePageTokens.push(nextPageToken);
    setIsPrevBtnActive("");
    getConfigurations(nextPageToken, searchVal, pageSize);
  };

  // function invoke when Previous button click
  const btnPrevClick = (e) => {
    e.preventDefault();
    if (prePageTokens.length > 1) {
      getConfigurations(prePageTokens[prePageTokens.length - 2], searchVal, pageSize);
      prePageTokens.pop();
    } else {
      prePageTokens.pop();
      getConfigurations("", searchVal, pageSize);
      setIsPrevBtnActive("disabled");
    }
    setIsNextBtnActive("");
  };

  // function use to set filter value
  const updateInputValue = (evt) => {
    setSearchVal(evt.target.value);
    if (evt.target.value) {
      setShowReset(true);
    } else {
      setShowReset(false);
      if (searchVal) {
        onLoad();
      }
    }
  };
  // placeholder to solve eslint error
  const handleClick = () => {};

  const generatePDF = (tableList, pdfDetails) => {
    const string = renderToString(<Pdf tableList={tableList} pdfDetails={pdfDetails} />);
    const pdf = new JsPDF("p", "px", "a4", true);
    const pdfName = `${pdfDetails.name}_${pdfDetails.standard}_${commonUtiles.formatDate(Date.now())}`
      .replaceAll(" ", "_")
      .replaceAll("/", "_");
    pdf.setProperties({
      title: pdfName,
    });
    const imageData = new Image();
    imageData.src = BrandIcon;
    imageData.onload = function () {
      pdf.addImage(imageData, "JPEG", 20, 45, 60, 14);
    };
    pdf.html(string, {
      callback() {
        window.open(pdf.output("bloburl"));
        setProcessing(false);
      },
    });
  };
  const sortNumber = (a, b) => a - b;
  const convertDataToValue = (data) => {
    const mapping = {
      dataMinus5: -5,
      data0: 0,
      dataPlus5: 5,
    };
    return mapping[data];
  };

  // toggle the password view icon
  const togglePwd = (e, config) => {
    setProcessing(true);
    configService
      .getReportData(config.id)
      .then((response) => {
        const callingTableList = [];
        const blockingTableList = [];
        const pdfDetails = {};
        pdfDetails.name = response?.data?.name;
        pdfDetails.standard = commonUtiles.getStdLabel(response?.data?.standard);
        pdfDetails.sytemType = response?.data?.sytemType;
        pdfDetails.job = config.job;
        pdfDetails.contract = config?.contract;
        pdfDetails.department = config?.department;
        pdfDetails.user = authService.getName();
        pdfDetails.date = commonUtiles.getDateForPDF(Date.now());
        pdfDetails.footerText = commonUtiles.getfooterText(response?.data?.standard);
        pdfDetails.emailId = config?.emailId;
        pdfDetails.vin = config?.vin;
        response?.data?.callingDataResult?.forEach((table) => {
          const tableDetails = {};
          tableDetails.rows = [];
          tableDetails.info = {};
          Object?.keys(table).forEach((tableRow) => {
            const data1 = table[tableRow];
            if (tableRow === "data") {
              Object.keys(data1).forEach((data) => {
                const header = [""];
                const row = [convertDataToValue(data)];
                const data2 = data1[data];
                Object.keys(data2)
                  .sort(sortNumber)
                  .forEach((tableData) => {
                    const data3 = data2[tableData];
                    header.push(tableData);
                    if (data3.toString().includes(".")) {
                      row.push(new Intl.NumberFormat().format(parseFloat(data3).toFixed(2)));
                    } else {
                      row.push(new Intl.NumberFormat().format(data3));
                    }
                  });
                tableDetails.rows.push(row);
                tableDetails.header = header;
              });
            } else {
              tableDetails.info[tableRow] = table[tableRow];
            }
          });
          callingTableList.push(tableDetails);
        });
        response?.data?.blockingDataResult?.forEach((table) => {
          const tableDetails = {};
          tableDetails.rows = [];
          tableDetails.info = {};
          Object.keys(table).forEach((tableRow) => {
            const data1 = table[tableRow];
            if (tableRow === "data") {
              Object.keys(data1).forEach((data) => {
                const header = [""];
                const row = [convertDataToValue(data)];
                const data2 = data1[data];
                Object.keys(data2)
                  .sort(sortNumber)
                  .forEach((tableData) => {
                    const data3 = data2[tableData];
                    header.push(tableData);
                    if (data3.toString().includes(".")) {
                      row.push(new Intl.NumberFormat().format(parseFloat(data3).toFixed(2)));
                    } else {
                      row.push(new Intl.NumberFormat().format(data3));
                    }
                  });
                tableDetails.rows.push(row);
                tableDetails.header = header;
              });
            } else {
              tableDetails.info[tableRow] = table[tableRow];
            }
          });
          blockingTableList.push(tableDetails);
        });
        const tableList = [];
        tableList[0] = callingTableList;
        tableList[1] = blockingTableList;
        generatePDF(tableList, pdfDetails);
      })
      .catch(() => {
        setProcessing(false);
      });
  };
  // handle onChange event of the dropdown
  const pageChange = (e) => {
    setPageSize(e);
    setPrePageTokens([]);
    setIsPrevBtnActive("disabled");
    setNextPageToken("");
    getConfigurations("", searchVal, e);
  };

  const renderBody = () =>
    configList.length ? (
      configList.map((config) => (
        <tr key={config.id}>
          <td>
            <div className="cell-mx-width" data-tip={config.emailId}>
              {config.emailId}
            </div>
            <ReactTooltip place="bottom" type="light" effect="solid" />
          </td>
          <td className="width-30">
            <div className="cell-mx-width" data-tip={config.configName}>
              {config.configName}
            </div>
          </td>
          <td className="width-20">
            <div>{commonUtiles.formatDate(config.lastModifiedDate)}</div>
          </td>
          <td className="text-center width-10">
            <div>
              {config.count ? (
                <a
                  onClick={(e) => {
                    togglePwd(e, config);
                  }}
                  role="button"
                  aria-label="toggle Pwd"
                  tabIndex={0}
                  onKeyDown={handleClick}
                >
                  <i aria-hidden="true" className="fa fa-file-pdf curser-pointer xx-large-icon" />
                </a>
              ) : (
                "N/A"
              )}
            </div>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="6">
          <div className="text-center py-5">
            <div className="mb-5">
              <i className="fa fa-2x fa-user text-primary" />
            </div>
            <h3 className="text-muted mb-3">No Data Found</h3>
            <p className="h6 text-muted font-weight-light">Please try with different keyword</p>
          </div>
        </td>
      </tr>
    );

  return (
    <>
      {processing ? <div className="fullloader" /> : null}
      <PageTitle title="Compliance Data" class="" />
      <div className={`card ${processing ? "loadingOpacity" : ""}`}>
        <div className="card-header bg-gray-dark">
          <div className="row  flex-grow-1 d-flex">
            <div className="col-auto mr-auto d-flex">
              <div className="input-group mr-1">
                <div className="input-icon">
                  <input
                    type="text"
                    className="form-control mg-rt-3 user-input search-input"
                    onKeyDown={onKeyDownHandler}
                    value={searchVal}
                    onChange={updateInputValue}
                    placeholder="Search for Config Name"
                    autoComplete="off"
                  />
                  {showReset ? (
                    <a
                      href="#"
                      onClick={onResetHandler}
                      aria-label="Reset handler"
                      className="reset-search input-icon-addon custom-i-addon"
                    >
                      <i className="fa fa-times" />
                    </a>
                  ) : null}
                </div>

                <span className="input-group-append">
                  <button className="btn btn-red" aria-label="handle search" type="button" onClick={handleSearch}>
                    <i className="fa fa-search" />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="table-min-height">
          <table className="table table-outline table-vcenter card-table">
            <thead className="text-nowrap">
              <tr>
                <th onClick={() => onSort("emailId", configList)} className="curser-pointer width-30 header-sticky">
                  Email Address
                  <span className={commonUtiles.setArrow(sort, "emailId")} />
                </th>
                <th onClick={() => onSort("configName", configList)} className="curser-pointer width-30 header-sticky">
                  Config Name
                  <span className={commonUtiles.setArrow(sort, "configName")} />
                </th>
                <th
                  onClick={() => onSort("lastModifiedDate", configList)}
                  className="curser-pointer width-20 header-sticky"
                >
                  Modified Date
                  <span className={commonUtiles.setArrow(sort, "lastModifiedDate")} />
                </th>
                <th className="text-center width-20 header-sticky">Compliance Data</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan="6" aria-label="loader">
                    <div className="dimmer h-9 active">
                      <div className="loader" />
                      <div className="dimmer-content" />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>{renderBody()}</tbody>
            )}
          </table>
        </div>

        {configData.length ? (
          <div className="card-footer">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end mb-0">
                <li>
                  <Select
                    onChange={pageChange}
                    options={commonUtiles.getPageSizes}
                    name="role"
                    className="select-sdd w-70 mr-3"
                    autoFocus={false}
                    menuPlacement="top"
                    isSearchable={false}
                    value={pageSize}
                    styles={commonUtiles.getDropDownStyleForPage()}
                  />
                </li>
                {isPrevBtnActive === "disabled" ? (
                  <li className="page-item disabled">
                    <span className="page-link">Previous</span>
                  </li>
                ) : (
                  <li className="page-item">
                    <button type="button" className="page-link" onClick={btnPrevClick}>
                      Previous
                    </button>
                  </li>
                )}
                {isNextBtnActive === "disabled" ? (
                  <li className="page-item disabled">
                    <span className="page-link">Next</span>
                  </li>
                ) : (
                  <li className="page-item">
                    <button type="button" className="page-link" onClick={btnNextClick}>
                      Next
                    </button>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default ConfigurationList;
