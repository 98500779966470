import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PageTitle from "../../Common/PageTitle";
import Spinner from "../../Common/Spinner";
import ConfigService from "../../../services/ConfigService";
import CommonUtiles from "../../../services/CommonUtiles";

const StatisticsData = () => {
  const [loading, setLoading] = useState(false);
  const [statisticsData, setStatisticsData] = useState({});
  const history = useHistory();

  // to get statistic list from API
  const getStatisticList = () => {
    setLoading(true);
    ConfigService.getStatisticData()
      .then((response) => {
        setLoading(false);
        setStatisticsData(response?.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStatisticList();
  }, []);

  // Redirect to Manage user page
  const redirectToUserList = (e, type) => {
    if (!loading) {
      history.push({ pathname: "/users", state: { usertype: type } });
    }
  };

  // keyDown handler for eslint error
  const onKeyDownHandler = (e, type) => {
    if (e.key === "Enter" || e.key === " ") {
      redirectToUserList(e, type);
    }
  };

  return (
    <>
      <PageTitle title="Statistics" class="" />
      <div className="row">
        <div className="col-xl-3">
          <div className="row">
            <div className="col-xl-12 col-sm-6">
              <div
                className="card p-5 h-150 curser-pointer"
                role="button"
                tabIndex={0}
                onClick={(e) => redirectToUserList(e, "active")}
                onKeyDown={(e) => onKeyDownHandler(e, "active")}
              >
                <h4 className="text-muted">Active Users</h4>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="display-3 text-success">
                    {statisticsData && statisticsData.activeUsers && statisticsData?.activeUsers}
                  </div>
                )}
              </div>
            </div>
            <div className="col-xl-12 col-sm-6">
              <div
                role="button"
                className="card p-5 h-150 curser-pointer"
                tabIndex={0}
                onClick={(e) => redirectToUserList(e, "disabled")}
                onKeyDown={(e) => onKeyDownHandler(e, "disabled")}
              >
                <h4 className="text-muted">Disabled Users</h4>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="display-3 text-warning">
                    {statisticsData && statisticsData.disabledUsers && statisticsData?.disabledUsers}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header bg-gray-dark">
              <h4 className="mb-0">Departments</h4>
            </div>
            <div className="list-group list-group-flush card-body-scrollable h-242">
              {loading ? (
                <div className=" list-group flex-grow-1 d-flex justify-content-center">
                  <Spinner />
                </div>
              ) : statisticsData?.departments && statisticsData?.departments?.length ? (
                statisticsData?.departments
                  ?.sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" }))
                  ?.map((dep) => <div className="list-group-item list-group-item-action">{dep}</div>)
              ) : (
                <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                  <h3 className="text-muted mb-3">No Data Found</h3>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-9">
          <div className="card">
            <div className="card-header bg-gray-dark">
              <h4 className="mb-0">Top 10 Active users by login count</h4>
            </div>
            <div className="table-responsive">
              <table className="table table-outline table-vcenter card-table">
                <thead className="text-nowrap table-head">
                  <tr>
                    <th className="header-sticky width-10">Rank</th>
                    <th className="width-20 header-sticky">Email Address</th>
                    <th className="width-15 header-sticky">First Name</th>
                    <th className="width-15 header-sticky">Last Name</th>
                    <th className="width-15 header-sticky">Company</th>
                    <th className="width-8 header-sticky">Role</th>
                    <th className="header-sticky text-center width-15">Login Count</th>
                    <th className="width-20 header-sticky">Last Login date</th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan="8" aria-label="td">
                        <Spinner />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {statisticsData && Object.keys(statisticsData)?.length ? (
                      statisticsData?.topUserBasedOnLoginCount?.map((user, index) => {
                        const convertDate = CommonUtiles.formatDate(user?.lastLoginTime);
                        return (
                          <tr>
                            <td className="text-center width-8">
                              <div className="cell-mx-width">{index + 1}</div>
                            </td>
                            <td className="width-20">
                              <div className="cell-mx-width" data-tip={user?.email}>
                                {user?.email}
                              </div>
                            </td>
                            <td className="width-15">
                              <div className="cell-mx-width" data-tip={user?.name}>
                                {user?.name}
                              </div>
                              <ReactTooltip place="bottom" type="light" effect="solid" />
                            </td>
                            <td className="width-15">
                              <div className="cell-mx-width" data-tip={user?.lastName}>
                                {user?.lastName}
                              </div>
                            </td>
                            <td className="width-15">
                              <div className="cell-mx-width" data-tip={user?.company}>
                                {user?.company}
                              </div>
                            </td>
                            <td className="width-8">
                              <div
                                className="cell-mx-width capitilize-column"
                                data-tip={user?.role?.toLowerCase()?.replace(/^\w/, (c) => c.toUpperCase())}
                              >
                                {user?.role}
                              </div>
                            </td>
                            <td className="text-center width-8">
                              <div className="cell-mx-width">{user?.loginCount}</div>
                            </td>
                            <td className="text-center width-15">
                              <div className="cell-mx-width" data-tip={convertDate}>
                                {convertDate}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="8">
                          <div className="text-center py-5">
                            <div className="mb-5">
                              <i className="fa fa-2x fa-user text-primary" />
                            </div>
                            <h3 className="text-muted mb-3">No Data Found</h3>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StatisticsData;
