// historyList Components
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import ShareHistoryService from "../../../services/ShareHistoryService";

// Ui Components
import PageTitle from "../../Common/PageTitle";

// Services
import commonUtiles from "../../../services/CommonUtiles";

const ShareHistory = () => {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showReset, setShowReset] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [filterVal, setFilterVal] = useState("");
  const [pageSize, setPageSize] = useState(commonUtiles.getPageSizes[1]);
  const [isPrevBtnActive, setIsPrevBtnActive] = useState("disabled");
  const [isNextBtnActive, setIsNextBtnActive] = useState("");
  const [prePageTokens, setPrePageTokens] = useState([]);
  const [nextPageToken, setNextPageToken] = useState("");
  const sortInside = {
    column: "lastModifiedDate",
    direction: "desc",
  };
  const [sort, setSort] = useState(sortInside);
  const [historyList, setHistoryList] = useState([]);

  // Method to sort table data
  const onSort = (column, data, listing) => {
    const direction = !listing ? (sort.direction === "asc" ? "desc" : "asc") : sort.direction;
    const sortedData = data.sort((a, b) => commonUtiles.compareValues(a, b, column, direction));
    if (direction === "desc") {
      sortedData.reverse();
    }
    setHistoryData(sortedData);
    setHistoryList(sortedData);
    setSort({
      column,
      direction,
    });
  };

  // Method to get share history data
  const getShareHistoryData = (filterValue, pageLength, pageToken) => {
    setLoading(true);
    ShareHistoryService.getShareHistory(filterValue, pageLength, pageToken)
      .then((response) => {
        setLoading(false);
        onSort(sort.column, response.data.configData, true);
        if (response.data.lastEvaluatedKey && response.data.configData.length) {
          setNextPageToken(response.data.lastEvaluatedKey.id);
          setIsNextBtnActive("");
        } else {
          setIsNextBtnActive("disabled");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // To call API and load the sharing history data
  const onLoad = (value) => {
    setPrePageTokens([]);
    setIsPrevBtnActive("disabled");
    setNextPageToken("");
    setSearchVal("");
    setFilterVal("");
    setShowReset(false);
    getShareHistoryData(value ? "" : filterVal, pageSize.value, "");
  };

  // function is used to get share history data on page load.
  useEffect(async () => {
    setProcessing(false);
    onLoad();
  }, []);

  // function invoke when Previous button click
  const btnPrevClick = (e) => {
    e.preventDefault();
    if (prePageTokens.length > 1) {
      getShareHistoryData(filterVal, pageSize.value, prePageTokens[prePageTokens.length - 2]);
      prePageTokens.pop();
    } else {
      getShareHistoryData(filterVal, pageSize.value, prePageTokens[prePageTokens.length - 2]);
      prePageTokens.pop();
      setIsPrevBtnActive("disabled");
    }
    setIsNextBtnActive("");
  };

  // function use to set filter value
  const updateInputValue = (evt) => {
    setSearchVal(evt.target.value);
    if (evt.target.value) {
      setShowReset(true);
    } else {
      setShowReset(false);
      if (searchVal) {
        onLoad(true);
      }
    }
  };

  // Function uses to filter configData' data
  const handleSearch = () => {
    if (searchVal) {
      setFilterVal(searchVal);
      setNextPageToken("");
      setIsPrevBtnActive("disabled");
      setPrePageTokens([]);
      getShareHistoryData(searchVal, pageSize.value, "");
      setShowReset(true);
    }
  };

  // Enter key handler
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  // Function uses for clear filter data
  const onResetHandler = (e) => {
    e.preventDefault();
    onLoad(true);
  };

  // function invoke on Next button click
  const btnNextClick = (e) => {
    e.preventDefault();
    prePageTokens.push(nextPageToken);
    setIsPrevBtnActive("");
    getShareHistoryData(filterVal, pageSize.value, nextPageToken);
  };

  // handle onChange event of the dropdown
  const pageChange = (e) => {
    setPageSize(e);
    setPrePageTokens([]);
    setIsPrevBtnActive("disabled");
    setNextPageToken("");
    getShareHistoryData(filterVal, e.value, "");
  };

  const renderBody = () =>
    historyList.length ? (
      historyList.map((historyItem) => (
        <tr key={historyItem.id}>
          <td>
            <div className="cell-mx-width" data-tip={commonUtiles.formatDate(historyItem.lastModifiedDate)}>
              {commonUtiles.formatDate(historyItem.lastModifiedDate)}
            </div>
          </td>
          <td>
            <div className="cell-mx-width" data-tip={historyItem.sourceEmail}>
              {historyItem.sourceEmail}
            </div>
          </td>
          <td>
            <div className="cell-mx-width" data-tip={historyItem.destinationEmail}>
              {historyItem.destinationEmail}
            </div>
            <ReactTooltip place="bottom" type="light" effect="solid" />
          </td>
          <td className="width-30">
            <div className="cell-mx-width" data-tip={historyItem.configName}>
              {historyItem.configName}
            </div>
          </td>
          <td className="text-center width-10">
            <div className="cell-mx-width" data-tip={historyItem.copyStatus ? historyItem.copyStatus : "N/A"}>
              <span
                className={
                  historyItem.copyStatus.toLowerCase() === "success" ? "badge badge-success" : "badge badge-danger"
                }
              >
                {historyItem.copyStatus ? historyItem.copyStatus : "N/A"}
              </span>
            </div>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="5">
          <div className="text-center py-5">
            <div className="mb-5">
              <i className="fa fa-2x fa-user text-primary" />
            </div>
            <h3 className="text-muted mb-3">No Data Found</h3>
            <p className="h6 text-muted font-weight-light">Please try with different keyword</p>
          </div>
        </td>
      </tr>
    );

  return (
    <>
      {processing ? <div className="fullloader" /> : null}
      <PageTitle title="Sharing History" class="" />
      <div className={`card ${processing ? "loadingOpacity" : ""}`}>
        <div className="card-header bg-gray-dark">
          <div className="row  flex-grow-1 d-flex">
            <div className="col-auto mr-auto d-flex">
              <div className="input-group mr-1">
                <div className="input-icon">
                  <input
                    type="text"
                    className="form-control mg-rt-3 user-input search-input"
                    onKeyDown={onKeyDownHandler}
                    value={searchVal}
                    onChange={updateInputValue}
                    placeholder="Search for email address"
                    autoComplete="off"
                    maxLength={100}
                  />
                  {showReset ? (
                    <a
                      href="#"
                      aria-label="link"
                      onClick={onResetHandler}
                      className="reset-search input-icon-addon custom-i-addon"
                    >
                      <i className="fa fa-times" />
                    </a>
                  ) : null}
                </div>

                <span className="input-group-append">
                  <button className="btn btn-red" aria-label="button" type="button" onClick={handleSearch}>
                    <i className="fa fa-search" />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="table-min-height">
          <table className="table table-outline table-vcenter card-table">
            <thead className="text-nowrap">
              <tr>
                <th
                  onClick={() => onSort("lastModifiedDate", historyList)}
                  className="curser-pointer width-20 header-sticky"
                >
                  Date
                  <span className={commonUtiles.setArrow(sort, "lastModifiedDate")} />
                </th>
                <th
                  onClick={() => onSort("sourceEmail", historyList)}
                  className="curser-pointer width-20 header-sticky"
                >
                  Source User
                  <span className={commonUtiles.setArrow(sort, "sourceEmail")} />
                </th>
                <th
                  onClick={() => onSort("destinationEmail", historyList)}
                  className="curser-pointer width-30 header-sticky"
                >
                  Destination Email Address
                  <span className={commonUtiles.setArrow(sort, "destinationEmail")} />
                </th>
                <th onClick={() => onSort("configName", historyList)} className="curser-pointer width-30 header-sticky">
                  Configuration Name
                  <span className={commonUtiles.setArrow(sort, "configName")} />
                </th>
                <th className="text-center width-20 header-sticky">Result</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan="5" aria-label="td">
                    <div className="dimmer h-9 active">
                      <div className="loader" />
                      <div className="dimmer-content" />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>{renderBody()}</tbody>
            )}
          </table>
        </div>

        {historyData.length ? (
          <div className="card-footer">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end mb-0">
                <li>
                  <Select
                    onChange={pageChange}
                    options={commonUtiles.getPageSizes}
                    name="role"
                    className="select-sdd w-70 mr-3"
                    autoFocus={false}
                    menuPlacement="top"
                    isSearchable={false}
                    value={pageSize}
                    styles={commonUtiles.getDropDownStyleForPage()}
                  />
                </li>
                {isPrevBtnActive === "disabled" ? (
                  <li className="page-item disabled">
                    <span className="page-link">Previous</span>
                  </li>
                ) : (
                  <li className="page-item">
                    <button type="button" className="page-link" onClick={btnPrevClick}>
                      Previous
                    </button>
                  </li>
                )}
                {isNextBtnActive === "disabled" ? (
                  <li className="page-item disabled">
                    <span className="page-link">Next</span>
                  </li>
                ) : (
                  <li className="page-item">
                    <button type="button" className="page-link" onClick={btnNextClick}>
                      Next
                    </button>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default ShareHistory;
